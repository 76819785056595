/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { RiArrowRightSLine, RiCheckLine, RiTeamLine, RiHeartLine, RiLineChartLine, RiBarChartBoxLine, RiSurveyLine, RiMedalLine } from "react-icons/ri"

const EmployeeEngagementGuidePage = () => {
  const engagementTemplates = [
    {
      id: "engagement-survey",
      title: "Employee Engagement Survey",
      description: "Comprehensive survey template with validated questions to measure and track employee engagement levels.",
      link: "/resources/templates/engagement-survey-template-download"
    },
    {
      id: "pulse-survey",
      title: "Weekly Pulse Survey",
      description: "Short, focused survey for regular check-ins to identify engagement trends and address issues quickly.",
      link: "/resources/templates/pulse-survey-template-download"
    },
    {
      id: "action-plan",
      title: "Engagement Action Plan",
      description: "Structured template for creating actionable improvement plans based on engagement survey results.",
      link: "/resources/templates/engagement-action-plan-download"
    },
    {
      id: "recognition-program",
      title: "Recognition Program Framework",
      description: "Guidelines and structure for implementing an effective employee recognition program.",
      link: "/resources/templates/recognition-program-framework-download"
    }
  ]

  return (
    <Layout>
      <SEO 
        title="Employee Engagement Guide | Strategies for a Motivated Workforce"
        description="Comprehensive guide to building and maintaining high employee engagement. Learn proven strategies to increase motivation, retention, and productivity."
        keywords={[
          "employee engagement strategies",
          "engagement survey templates",
          "workforce motivation",
          "employee retention tactics",
          "recognition programs",
          "employee satisfaction",
          "engagement metrics",
          "workplace culture",
          "employee experience",
          "engagement action plans"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <h1 sx={{
              fontSize: ['2rem', '2.5rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              The Complete Guide to Employee Engagement
            </h1>
            
            <p sx={{
              fontSize: '1.1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              Build a highly engaged workforce with proven strategies that increase motivation, 
              retention, and productivity while creating a positive company culture.
            </p>
            
            <div sx={{
              display: 'flex',
              gap: 3,
              justifyContent: 'center',
              flexWrap: 'wrap'
            }}>
              <a href="#templates" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                }
              }}>
                Get Templates <RiSurveyLine />
              </a>
              
              <Link to="/tools/engagement-assessment" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                border: '2px solid',
                borderColor: 'primary',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                }
              }}>
                Try Our Engagement Assessment <RiBarChartBoxLine />
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div sx={{
        bg: 'white',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3,
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '2fr 1fr'],
          gap: 5
        }}>
          {/* Left Column - Main Content */}
          <div>
            {/* Introduction */}
            <div sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Why Employee Engagement Matters
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 3
              }}>
                Employee engagement goes far beyond job satisfaction – it measures the emotional commitment employees 
                have to their organization and its goals. Engaged employees are passionate, energetic, and fully invested 
                in their work. They don't just work for a paycheck or promotion; they work on behalf of the organization's goals.
              </p>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 3
              }}>
                Organizations with high employee engagement enjoy 21% higher profitability, 41% lower absenteeism, 
                and 59% less turnover. These metrics translate directly to reduced operational costs and higher revenue. 
                This guide provides proven strategies to build and maintain an engaged workforce.
              </p>
            </div>
            
            {/* Key Drivers of Engagement */}
            <div sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Key Drivers of Employee Engagement
              </h2>
              
              <div sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                gap: 4,
                mb: 4
              }}>
                {/* Meaningful Work */}
                <div sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    <RiHeartLine />
                  </div>
                  
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    mb: 2,
                    color: 'text'
                  }}>
                    Meaningful Work
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    Employees who find purpose and meaning in their work are more engaged, productive, and likely to stay with the organization.
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    fontSize: '0.9rem'
                  }}>
                    {[
                      "Clear job purpose and value",
                      "Alignment with personal values",
                      "Sense of contribution to larger goals",
                      "Autonomy and ownership",
                      "Work that leverages strengths"
                    ].map((item) => (
                      <li key={item} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        color: 'text'
                      }}>
                        <span sx={{ color: 'primary', mr: 2 }}><RiCheckLine size={14} /></span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                
                {/* Leadership & Management */}
                <div sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    <RiTeamLine />
                  </div>
                  
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    mb: 2,
                    color: 'text'
                  }}>
                    Leadership & Management
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    The relationship between employees and their direct managers is one of the most critical factors in engagement levels.
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    fontSize: '0.9rem'
                  }}>
                    {[
                      "Regular, meaningful feedback",
                      "Clear expectations and goals",
                      "Supportive coaching relationship",
                      "Trust and psychological safety",
                      "Recognition of contributions"
                    ].map((item) => (
                      <li key={item} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        color: 'text'
                      }}>
                        <span sx={{ color: 'primary', mr: 2 }}><RiCheckLine size={14} /></span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                
                {/* Growth & Development */}
                <div sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    <RiLineChartLine />
                  </div>
                  
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    mb: 2,
                    color: 'text'
                  }}>
                    Growth & Development
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    Employees who see a future with the organization and have opportunities to develop are significantly more engaged.
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    fontSize: '0.9rem'
                  }}>
                    {[
                      "Career development pathways",
                      "Learning opportunities",
                      "Skill expansion and mastery",
                      "Mentorship programs",
                      "Internal mobility options"
                    ].map((item) => (
                      <li key={item} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        color: 'text'
                      }}>
                        <span sx={{ color: 'primary', mr: 2 }}><RiCheckLine size={14} /></span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                
                {/* Recognition & Rewards */}
                <div sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    <RiMedalLine />
                  </div>
                  
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    mb: 2,
                    color: 'text'
                  }}>
                    Recognition & Rewards
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    Acknowledging employee contributions and rewarding performance are powerful drivers of engagement and motivation.
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    fontSize: '0.9rem'
                  }}>
                    {[
                      "Timely and specific recognition",
                      "Peer-to-peer appreciation",
                      "Formal rewards programs",
                      "Non-monetary recognition",
                      "Performance-based incentives"
                    ].map((item) => (
                      <li key={item} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        color: 'text'
                      }}>
                        <span sx={{ color: 'primary', mr: 2 }}><RiCheckLine size={14} /></span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Templates Section */}
            <div id="templates" sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Employee Engagement Templates & Resources
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4
              }}>
                Use these templates to measure, track, and improve employee engagement in your organization.
              </p>
              
              <div sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                gap: 4,
                mb: 4
              }}>
                {engagementTemplates.map((template) => (
                  <div key={template.id} sx={{
                    p: 3,
                    borderRadius: '8px',
                    boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                    '&:hover': {
                      transform: 'translateY(-3px)',
                      boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                    }
                  }}>
                    <h3 sx={{
                      fontSize: '1.2rem',
                      fontWeight: 700,
                      mb: 2,
                      color: 'text'
                    }}>
                      {template.title}
                    </h3>
                    
                    <p sx={{
                      fontSize: '0.95rem',
                      lineHeight: 1.5,
                      mb: 3,
                      color: 'text'
                    }}>
                      {template.description}
                    </p>
                    
                    <Link to={template.link} sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 1,
                      color: 'primary',
                      fontWeight: 600,
                      fontSize: '0.95rem',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}>
                      View & Print Template <RiArrowRightSLine />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          
          {/* Right Column - Sidebar */}
          <div>
            {/* Engagement Statistics Box */}
            <div sx={{
              p: 4,
              mb: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
              background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
            }}>
              <h3 sx={{
                fontSize: '1.2rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Engagement By The Numbers
              </h3>
              
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                {[
                  "Only 36% of employees are actively engaged in their workplace",
                  "Companies with high engagement are 21% more profitable",
                  "Engaged employees are 87% less likely to leave their organizations",
                  "Teams with high engagement see 41% reduction in absenteeism",
                  "Engaged companies grow profits 3x faster than competitors",
                  "87% of employees expect their employer to support them in balancing work and personal commitments"
                ].map((item) => (
                  <li key={item} sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 2,
                    color: 'text'
                  }}>
                    <span sx={{ color: 'primary', mr: 2, mt: '4px', flexShrink: 0 }}><RiCheckLine /></span>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
            
            {/* Need Help Box */}
            <div sx={{
              p: 4,
              mb: 4,
              borderRadius: '8px',
              bg: 'primary',
              color: 'white',
              boxShadow: '0 2px 15px rgba(0,0,0,0.1)'
            }}>
              <h3 sx={{
                fontSize: '1.2rem',
                fontWeight: 700,
                mb: 3,
                color: 'white'
              }}>
                Need Help With Your Engagement Strategy?
              </h3>
              
              <p sx={{
                fontSize: '0.95rem',
                mb: 4,
                lineHeight: 1.6
              }}>
                Our HR technology experts can help you implement digital engagement tools and develop effective strategies to build a more engaged workforce.
              </p>
              
              <Link to="/contact" sx={{
                display: 'inline-block',
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '0.95rem',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
              }}>
                Get Expert Advice
              </Link>
            </div>
            
            {/* Related Resources */}
            <div sx={{
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)'
            }}>
              <h3 sx={{
                fontSize: '1.2rem',
                fontWeight: 700,
                mb: 3,
                color: 'text'
              }}>
                Related Resources
              </h3>
              
              <div sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3
              }}>
                <Link to="/guides/performance-reviews" sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  textDecoration: 'none',
                  color: 'text',
                  p: 2,
                  borderRadius: '4px',
                  transition: 'background-color 0.2s ease',
                  '&:hover': {
                    bg: 'rgba(0,0,0,0.03)'
                  }
                }}>
                  <span sx={{ fontWeight: 600, color: 'primary' }}>Performance Review Guide</span>
                  <span sx={{ fontSize: '0.9rem' }}>Learn how to conduct effective performance reviews that motivate and engage employees.</span>
                </Link>
                
                <Link to="/tools/engagement-assessment" sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  textDecoration: 'none',
                  color: 'text',
                  p: 2,
                  borderRadius: '4px',
                  transition: 'background-color 0.2s ease',
                  '&:hover': {
                    bg: 'rgba(0,0,0,0.03)'
                  }
                }}>
                  <span sx={{ fontWeight: 600, color: 'primary' }}>Engagement Assessment Tool</span>
                  <span sx={{ fontSize: '0.9rem' }}>Evaluate your current engagement levels and get actionable recommendations.</span>
                </Link>
                
                <Link to="/tools/turnover-cost-calculator" sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  textDecoration: 'none',
                  color: 'text',
                  p: 2,
                  borderRadius: '4px',
                  transition: 'background-color 0.2s ease',
                  '&:hover': {
                    bg: 'rgba(0,0,0,0.03)'
                  }
                }}>
                  <span sx={{ fontWeight: 600, color: 'primary' }}>Turnover Cost Calculator</span>
                  <span sx={{ fontSize: '0.9rem' }}>Calculate how much disengagement and turnover is costing your organization.</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EmployeeEngagementGuidePage 